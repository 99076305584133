.content-area {
  height: calc(100% - 75px);
  left: 0;
  top: 75px;
  min-height: calc(100% - 75px);
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 32px 20px 32px 20px;
}

.card-grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-gap: 24px 24px;
  justify-content: flex-start;
  grid-template-columns: 1fr;
  user-select: none;
  box-sizing: border-box;
  padding: 1em 2em;
}

@media all and (min-width: 768px) {
  .content-area {
    height: max-content;
    left: var(--nav-space);
    min-height: 100%;
    position: absolute;
    width: calc(100% - 40px - var(--nav-space));
    top: 0;
  }

  .card-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (min-width: 1280px) {
  .card-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media all and (min-width: 1920px) {
  .card-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.section-gap,
.main-content-inner--view-mode {
  padding: 0 !important;
}
