.del-btn > svg:hover {
  fill: var(--brand-colour);
  stroke: var(--brand-colour);
}

.del-btn {
  opacity: 0;
  transition: opacity 0.2s;
}

/* Container element used for infinite scrolling */
.overflow-box {
  height: 5px;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
}
