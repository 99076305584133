:root {
  --brand-colour: #d63284;
  --brand-colour-20: rgba(214, 50, 132, 0.2);
  --active-link-colour: #1e0556;
  --link-colour: #bbc5d5;
  --input-colour: #f7f8fa;
  /* --text-colour: #354052; */
  --nav-left-container-width: 75px;
  --nav-height: 56px;
  --light-nav: #fff;
  --light-text-colour: #354052;
  --nav-colour-inverse: #141414;
  --pad-default: 12px;
  --text-colour: var(--light-text-colour);
  --nav-colour: var(--light-nav);
  --info-text-colour: #65676e;
  --error-text-colour: #ff476f;
  --nav-space: 180px;
  --header-colour: #3d202f;
  --sub-header-colour: #584c7f;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

* {
  box-sizing: border-box;
}

.navlink,
.navlink-active {
  text-decoration: none;
}

.navlink {
  color: black;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
}

.navlink-active {
  font-weight: bold;

  /* color: var(--brand-colour); */
}

.navlink::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;

  /* background-color: var(--brand-colour); */
  background-color: var(--link-colour);
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

.navlink:hover::after {
  transform: scaleX(1);
  transform-origin: bottom center;
}

.sel-btn::after {
  content: " ";
  display: block;
  width: 3px;
  height: 27px;
  background-color: var(--brand-colour);
  border-radius: 10px;
  position: relative;
  left: 49px;
  top: 0;
  margin-top: -24px;
}

.active-link,
.nav-link {
  display: block;
  text-align: center;
}

.active-link > svg {
  fill: var(--active-link-colour);
  stroke: var(--active-link-colour);
}

.active-link::after {
  content: " ";
  display: block;
  width: 3px;
  height: 30px;

  /* background-color: var(--brand-colour); */
  background-color: var(--link-colour);
  border-radius: 10px;
  margin-top: -33px;
  margin-left: 59px;
}

.logo-icon {
  width: 100%;
}

.nav-button {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  font: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin: 0;
  background: transparent;
  overflow: visible;
  text-transform: none;
  color: inherit;
  outline: none;
  border: none;
  text-align: inherit;
  padding: 12px;
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

/* .nav-button > svg {
  fill: var(--link-colour);
  stroke: var(--link-colour);
} */

/* .nav-button:hover {
  filter:
    invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
} */

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

nav.app-bar {
  background-color: var(--nav-colour);
  color: #fff;
  position: fixed;
  width: var(--nav-left-container-width);

  /* z-index: 5; */
  left: 0;
  top: 0;
  height: 100%;

  /* color: var(--link-colour); */
  padding: var(--pad-default);
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--pad-default);
  box-shadow: 3px 0 6px rgb(0 0 0 / 6%);
  /* z-index: 100; */
  border-radius: 48px;
  background: #d63284;
  margin: 20px;
  width: 150px;
  height: calc(100% - 40px);
  align-items: center;
  padding: 20px;
}

nav > ul {
  text-align: center;
  margin-right: calc(var(--pad-default) * -1);
  margin-left: calc(var(--pad-default) * -1);
  display: flex;
  flex-direction: column;
}

.app-bar .menu-logo {
  font-size: 50px;
}

nav > ul > .active::after {
  /* border-right: 4px solid var(--brand-colour); */

  /* border-right: 4px solid var(--link-colour);
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease; */
}

nav .menu-items {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 1em 0;
}

nav .menu-items::before {
  top: 0;
}

nav .menu-items::after {
  bottom: 0;
}

nav .menu-items::before,
nav .menu-items::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 5.91%, rgba(255, 255, 255, 0.6) 51.23%, rgba(255, 255, 255, 0) 93.1%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
}

nav .menu-items i {
  text-align: center;
}

nav .nav-container {
  width: 100%;
  height: 100%;
  color: var(--text-colour);
  padding: var(--pad-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

nav .nav-container .menu-items {
  position: fixed;
  bottom: var(--nav-height);
  left: 0;
  width: var(--nav-left-container-width);
  padding: var(--pad-default);
  background: var(--nav-colour-inverse);
  background-color: var(--nav-colour);
  display: flex;
  left: calc(var(--nav-left-container-width) * -1);
  transition: transform 0.4s ease-in-out;
  z-index: 9999;
  color: var(--link-colour);
  justify-content: space-between;
  bottom: auto;
  top: 0;
  flex-direction: column;
  height: 100%;
}

span.button-label {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.button-label span.label-icon {
  display: inherit;
  margin-right: 8px;
}

.label-icon.dark svg {
  fill: var(--text-colour);
  stroke: var(--text-colour);
  width: 24px;
  height: 24px;
}

span.label-icon svg {
  fill: white;
  stroke: white;
  width: 16px;
  height: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: unset;
}

label {
  user-select: none;
}

table {
  table-layout: fixed;
}

th, td {
  /* overflow-x: clip;
  text-overflow: ellipsis; */
  overflow-wrap: break-word;
}
