div.skeleton-item {
  /* background: var(--input-colour) !important; */

  /* animation: loading 2s infinite linear; */
  border-radius: 4px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  position: relative;
  animation: loadingAnimation 3.5s infinite ease;
}

/* .skeleton-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
} */

div.skeleton-container {
  border-radius: 4px;
  padding: 24px;
  display: grid;
  grid-gap: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
}

@keyframes loading {
  0% {
    transform: translateX(0);
  }

  50%,
  100% {
    transform: translateX(460px);
  }
}

@keyframes loadingAnimation {
  0% { opacity: 1; }
  50% { opacity: 0.25; }
  100% { opacity: 1; }
}
