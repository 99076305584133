.search-header-container {
  display: flex;
  justify-content: flex-end;
}

.search-header-container > * {
  margin-right: 24px;
}

.search-header-container > *:last-child {
  margin-right: 0;
}

.search-header-container > .input-content {
  max-width: 500px;
  flex-grow: 1;
}

.search-input {
  background-color: white !important;
}

.header-btn-add-new {
  padding: 4px 12px !important;
}
