.tabs-container {
  overflow: hidden;
  position: relative;
  width: 100%;

  /* height: 448px; */
}

.tabs-header {
  display: flex;
  color: var(--brand-colour);
  align-items: center;
  margin: 8px 24px;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: fit-content;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
}

.tabs-header li {
  width: 100%;
  list-style: none;
  text-align: center;
}

.tabs-header li:not(.tab-active-header) {
  cursor: pointer;
}

.underline {
  opacity: 1;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: var(--brand-colour);
  position: relative;
  z-index: 1;
}

.underline-bg {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: var(--link-colour);
  position: absolute;
  bottom: 0;
}

section {
  position: relative;

  /* position: absolute; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.tabs-header h4 {
  margin: 16px;
  font-weight: 400;
  transition: font-weight 0.3s ease;
  color: var(--text-colour);
}

.active-header h4 {
  color: white;
  font-weight: 600;
}

.tab-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tab-active-header h4 {
  font-weight: 600;
  color: var(--brand-colour);
}

.tab-disabled-header {
  opacity: 0.5;
  pointer-events: none;
}
