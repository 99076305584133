.display-box {
  background: white;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  width: 300px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  border: none;
  outline: none;
  cursor: pointer;
}

.display-box > * {
  color: var(--text-colour);
  transition: all 0.3s;
  width: 100%;
}

.display-box:disabled {
  filter: saturate(0);
  opacity: 0.5;
}

.display-box-value {
  text-align: right;
  font-size: 128px;
  font-weight: 900;
  line-height: initial;
  color: var(--brand-colour);
}

.display-box-title {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  text-align: start;
}

.display-box-title > div {
  margin-top: 6px;
}

.display-box-title > svg {
  fill: var(--text-colour);
  stroke: var(--text-colour);
  transition: all 0.3s;
}

.display-box:hover:enabled,
.display-box:active:enabled {
  background: var(--brand-colour);
  color: var(--brand-colour);
  transform: scale(97%);
}

.display-box:hover:enabled > * {
  color: white;
}

.display-box:active:enabled {
  transform: scale(95%);
}

.display-box:hover:enabled > .display-box-title > svg {
  fill: white;
  stroke: white;
}
