.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.modal-popup.is-visible {
  opacity: 1;
  z-index: 10;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.modal-outer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-popup-container {
  position: relative;
  width: 90%;
  max-width: 600px;
  max-height: 600px;
  margin: 4em auto;
  background: white;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-40px);
  transition-property: transform;
  transition-duration: 0.3s;
  height: auto;
}

.modal-popup-container.large {
  max-width: 80%;
  max-height: 80%;
}

.modal-popup-container > div {
  padding: 3em 1em;
  min-height: auto;
  max-height: 100%;
  overflow: auto;
}

.modal-popup-container .modal-buttons {
  padding: 0;
  margin: 0;
}

.modal-popup-container .modal-buttons::after {
  content: "";
  display: table;
  clear: both;
}

.modal-popup-container .modal-buttons li {
  float: left;
  width: 50%;
  list-style: none;
}

.modal-popup-container .modal-buttons.single li {
  width: 100%;
}

.modal-popup-container .modal-buttons button {
  display: block;
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: white;
  transition: background-color 0.3s, filter 0.3s;
  width: 100%;
  outline: none;
  border: none;
  font-size: 14px;
  filter: brightness(100%);
}

.modal-popup-container .modal-buttons li:first-child button {
  background: var(--brand-colour);
  border-radius: 0 0 0 0.25em;
}

.modal-popup-container .modal-buttons.single li button {
  background: var(--brand-colour) !important;
  border-radius: 0 0 0.25em 0.25em;
}

.modal-popup-container .modal-buttons li:last-child button {
  background: var(--text-colour);
  border-radius: 0 0 0.25em 0;
}

.modal-popup-container .modal-buttons.single li button:hover {
  filter: brightness(110%) !important;
}

.modal-popup-container .modal-buttons li:first-child button:hover {
  filter: brightness(110%);
}

.modal-popup-container .modal-buttons li:last-child button:hover {
  filter: brightness(190%);
}

.is-visible .modal-popup-container {
  transform: translateY(0);
}

@media only screen and (min-width: 1170px) {
  .modal-popup-container {
    margin: 8em auto;
  }
}

.height-100 {
  height: 100%;
}

.height-auto {
  height: auto;
}

.width-100 {
  width: 100% !important;
}
