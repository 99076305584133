.page-header-container {
  height: 100%;
  line-height: 100%;
  font-size: 1.25em;
  color: var(--header-colour);
  user-select: none;
}

.page-header-container .separator {
  font-weight: 200;
}

.page-header-container .page-title {
  font-weight: 300;
}
