.card-indicator {
  background-color: var(--brand-colour);
  width: 8px;
  border-radius: 2px;
  box-sizing: border-box;
  display: block;
}

.display-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  background: white;
  font-family: inherit;
}

.card-content-container {
  text-align: start;
}
