.mediahub-icon-button {
  background: none;
  border: none;
  outline: none;
  font-size: 24px;
  transition: background 0.3s ease;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  user-select: none;
  cursor: pointer;
}
