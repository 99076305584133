.action-btn {
  width: 100%;
}

.openid-link {
  color: var(--brand-colour);
  text-decoration: underline;
  text-align: center;
}

.openid-link:visited {
  color: var(--brand-colour);
}

table {
  table-layout: fixed;
}

th, td {
  /* overflow-x: clip;
  text-overflow: ellipsis; */
  overflow-wrap: break-word;
}
