.nav-tr {
  cursor: pointer;
}

.nav-tr:hover {
  filter:
    invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}

li > * {
  /* font-weight: normal !important;
  font-size: 14px !important; */
}
